import { getFamily, getTarget, handleStorage, getMarket, getAppliedDiscount, DEFAULT_LIMIT } from 'utils/helpers';
import { IS_CAMPER } from './constants/system';

const getfilter = (filter, type, key, value) => {
  if (filter) {
    key.push(type);
    filter.forEach((col) => {
      value.push(col.label);
    });
  }
};

export const CONTENT_GROUPING = {
  SECTION: {
    HOME: 'home',
    CONTENT: 'content',
    LANDING: 'landing',
    GRID: 'grid',
    CUSTOMER_SERVICE: 'customer service',
    FAQ: 'at cliente faq',
    CONTACT: 'contact',
    SHOPS: 'store locator',
    PDP: 'detail',
    BAG: 'bag',
    CHECKOUT: 'checkout',
    THANK_YOU: 'thank you page',
    ACCOUNT: 'my account',
    RETURNS: 'return & exchange',
    OTHER: 'other',
  },
  CATEGORY: {
    W: 'women',
    M: 'men',
    K: 'kids',
    X: 'unisex',
    G: 'goods',
    OTHER: 'other',
    SEARCH: 'search',
    RESULT: 'result',
    DETAIL: 'detail',
    FUNNEL: 'funnel',
    ORDERS: 'my orders',
    WISHLIST: 'wishlist',
    PROFILE: 'my profile',
    ORDER_DETAILS: 'order details',
    PROCESS: 'process',
  },
  TYPE: {
    1: 'shoes',
    2: 'accessories',
    5: 'apparel',
    6: 'campergoods',
    OTHER: 'other',
    ACCESSORIES: 'accessories',
    APPAREL: 'apparel',
    GOODS: 'campergoods',
    TWS: 'tws krk',
    TECH: 'technology',
    LAB: 'camperlab',
    RECAMPER: 'recamper',
    BCORP: 'bcorp',
    HISTORY: 'history',
    TOGETHER: 'together',
    SOCIAL: 'social responsibility',
    BETTER_WOMAN: 'better collection women',
    ARCHIVE: 'archive collection',
  },
  DETAIL: {
    OTHER: 'other',
  },
};

const pageNameAlias = {
  Shoplocator: 'store locator',
  ShoplocatorStore: 'store page',
  Purchase: 'checkout',
  Order: 'thank you page',
  OrderOffline: 'thank you page',
  Account: 'my account',
  AccountOrders: 'my account',
  AccountProfile: 'my account',
  AccountWishlist: 'my account',
  AccountOrderDetails: 'my account',
};

const accountCategoryAlias = {
  myorders: 'my orders',
  myprofile: 'my profile',
  mycommunications: 'my communications',
};

const categoryFunnel = () => ({ page_category: 'funnel' });
const hyphenUnderScoreRegex = /(-|_)/gi;

const pageNameMapper = {
  // en este mapper están las funciones de cada página
  // que require datos dinámicos
  content: (locale, props) => {
    // type es lo primero que viene después de content
    // si no hay content lo primero
    // detail es siempre lo último de la url
    // si vale lo mismo que type entonces es other
    const isDirectContent = props.url.includes('content');
    const urlFragments = props.url.split(locale);

    if (urlFragments?.length === 2) {
      const path = urlFragments[1].split('/').filter(Boolean);

      if (path) {
        const lastElementInPath = path[path.length - 1];
        const page_type = path[isDirectContent ? 1 : 0]?.replaceAll(hyphenUnderScoreRegex, ' ');
        const page_detail = page_type === lastElementInPath ? 'other' : lastElementInPath?.replaceAll(hyphenUnderScoreRegex, ' ');

        return { page_type, page_detail };
      }
    }

    return false;
  },
  landing: (locale, props) => {
    const [, campaignCode] = props.urlCanonical.split('/landings/');

    if (campaignCode) {
      return { page_type: campaignCode?.replaceAll(hyphenUnderScoreRegex, ' ') };
    }

    return false;
  },
  grid: (locale, props) => {
    const page_category = CONTENT_GROUPING.CATEGORY[props.targetRealID];
    const page_type = CONTENT_GROUPING.TYPE[props.familyRealID];
    const market = getMarket(locale);
    let page_detail = 'all';
    if (props.url !== undefined) {
      const path = props?.url?.split(`${market}/`)[1];
      const urlFragments = path?.split('/').splice(2) ?? [];
      page_detail = urlFragments.length === 0 ? 'all' : urlFragments.join(' ').replaceAll('_', ' ');
    }

    return { page_category, page_type, page_detail };
  },
  'store locator': (locale, props) => {
    const path = props.url.split('/').filter(Boolean);
    let page_category = 'search';

    if (path.length > 1) {
      page_category = 'result';
    }

    return { page_category };
  },
  'store page': () => ({ page_category: 'detail', page_section: 'store locator' }),
  detail: (locale, props) => {
    const page_category = CONTENT_GROUPING.CATEGORY[props.targetRealID];
    const page_type = CONTENT_GROUPING.TYPE[props.familyRealID];
    const market = getMarket(locale);
    const [, path] = props.url.split(`${market}/`);
    const urlFragments = path.split('/').splice(2);
    const [page_detail] = urlFragments;

    return { page_category, page_type, page_detail };
  },
  bag: categoryFunnel,
  checkout: categoryFunnel,
  GlobalECheckout: categoryFunnel,
  'thank you page': categoryFunnel,
  'my account': (locale, props) => {
    const urlFragments = props.url.split('/').filter(Boolean);
    const [, category] = urlFragments;

    if (urlFragments.length === 2) {
      return { page_category: accountCategoryAlias[category] || category };
    }
    if (urlFragments.length === 3) {
      // solo puede ser en orderdetails
      return { page_category: 'order details', page_section: 'my account' };
    }
    return false;
  },
};

export const getMonitorValues = (locale, pageName, gtmProps) => {
  const section = Object.keys(pageNameAlias).includes(pageName) ? pageNameAlias[pageName] : pageName;
  const responseObject = {
    page_section: section?.toLowerCase().replaceAll('_', ' '),
    page_category: CONTENT_GROUPING.CATEGORY.OTHER,
    page_type: CONTENT_GROUPING.TYPE.OTHER,
    page_detail: CONTENT_GROUPING.DETAIL.OTHER,
  };

  const pageMapperFunction = pageNameMapper[section];

  if (pageMapperFunction) {
    try {
      const overridingValue = pageMapperFunction(locale, gtmProps);

      if (overridingValue !== false) {
        Object.keys(overridingValue).forEach((key) => {
          responseObject[key] = overridingValue[key];
        });
      }
    } catch (e) {
      console.error('[Monitoring Error]', e);
      return false;
    }
  }

  console.log('[Monitor Content] Result:', responseObject);
  return responseObject;
};

export const categories = {
  W: 'women',
  M: 'men',
  K: 'kids',
  G: 'goods',
  X: 'unisex',
};

export const setGTMStorage = (currentList, listName) => {
  handleStorage('removeItem', 'gtmCurrentListId');
  handleStorage('removeItem', 'gtmCurrentListName');

  handleStorage('setItem', 'gtmCurrentListId', currentList);
  handleStorage('setItem', 'gtmCurrentListName', listName);
};

export const getGTMStorage = () => {
  const currentListId = handleStorage('getItem', 'gtmCurrentListId');
  const currentListName = handleStorage('getItem', 'gtmCurrentListName');

  return {
    itemListName: currentListName,
    itemListId: currentListId,
  };
};

export const parseProductsGTM = (products) => {
  const newProductsGtm = [];

  products?.forEach((product, i) => {
    const oldPrice = product.prices.previous === undefined ? 0 : product.prices.previous;
    newProductsGtm[i] = {
      cod_item: product.id,
      name: product.name,
      old_price_item: oldPrice,
      price_item: product.prices.current,
      tipology: product.tipology,
    };
  });

  return newProductsGtm;
};

export const parseEanGTM = (sizes) => {
  const ean = [];

  sizes?.forEach((size) => {
    ean[size.label] = size.ean;
  });

  return ean;
};

export const parseItemsBagGTM = (bag) => {
  const items = [];
  bag?.forEach((item, i) => {
    const gender = getTarget(item.target);
    const family = getFamily(item.family);
    const oldPrice =
      item.price.previous === undefined || (item.price.previous !== undefined && item.price.previous === item.price.current) ? 0 : item.price.previous;
    items[i] = {
      cod_item: item.productId,
      currency: item?.price?.currencySap || item?.price?.currency,
      family,
      family_id: item.family,
      gender,
      gender_id: item.target,
      image_bag: item.thumbnail,
      name: item.name,
      old_price_item: oldPrice,
      price_item: item.price.current,
      price_item_eur: item.price.currentEur,
      quantity_item: 1,
      tipology: item.tipology,
    };
  });

  return items;
};

export const parseItemsOrderGTM = (bag) => {
  const items = [];

  bag?.forEach((item, i) => {
    const gender = getTarget(item.targetId);
    const family = getFamily(item.familyId);
    const oldPrice =
      item.prices.previous === undefined || (item.prices.previous !== undefined && item.prices.previous === item.prices.current) ? 0 : item.prices.previous;
    const currencySap = item.prices.currencySap === undefined ? item.prices.currency : item.prices.currencySap;
    const decimals = 2;
    const { size } = item;

    // LOOKUPS EVERYWHERE PORQUE EN MX NO LLEGA CURRENT EUR Y CASCA

    items[i] = {
      sapSize: size,
      cod_item: item.id,
      currency: currencySap,
      family,
      family_id: item.familyId,
      gender,
      gender_id: item.targetId,
      name: item.name,
      old_price_item: oldPrice,
      price_item: item?.prices?.current,
      price_item_eur: item?.prices?.currentEur,
      price_item_net: item?.prices?.netPrice,
      price_item_eur_net: item?.prices?.netPriceEur,
      quantity_item: 1,
      tipology: item.tipology,
    };
  });

  return items;
};

export const parseBagEcommerceGTM = (bag) => {
  const items = [];
  bag?.forEach((item, i) => {
    items[i] = {
      brand: item.size || '',
      category: item.category,
      coupon: '',
      id: item.productId || item.id,
      name: item.name,
      price: item.prices?.current || item.price?.current,
      quantity: 1,
    };
  });
  return items;
};

export const parseProductsEcommerce = (products, list) => {
  const newProductsEcommerce = [];

  products?.forEach((product, i) => {
    const position = i + 1;
    newProductsEcommerce[i] = {
      brand: '',
      coupon: '',
      category: product.category,
      id: product.id,
      list,
      name: product.name,
      position,
      price: product.prices.current,
    };
  });

  return newProductsEcommerce;
};

export const parseProductsEcommerceGA4 = (products, listId, listName, page = 0) => {
  const newProductsEcommerce = [];
  const itemListName = listName || listId;

  products?.forEach((product, i) => {
    const itemsData = handleStorage('getItem', 'gtmItems');
    const itemsDataParsed = itemsData === undefined ? {} : JSON.parse(itemsData);
    const position = i + 1;
    const productCategory = product.category?.split('/') || '';
    const addToCartDataFromOrigin = itemsDataParsed[product.id];
    const indexForElement = Number(page) * DEFAULT_LIMIT + Number(position);
    newProductsEcommerce[i] = {
      item_id: product.id, // "K201322-003"
      item_brand: IS_CAMPER ? 'camper' : 'nnormal',
      item_category: productCategory?.length && productCategory[0] in categories ? categories[productCategory[0]] : '', // 'women
      item_category2: productCategory?.length >= 2 ? productCategory[1].toLowerCase() : '', // shoes
      item_category3: productCategory?.length >= 2 ? productCategory[2].toLowerCase() : '', // sandals
      item_category4: product.nameBase?.toLowerCase() || product.name?.toLowerCase() || '', // <linea>
      item_category5: product.size || '',
      coupon: '',
      item_variant: product.color?.toLowerCase() || '',
      item_list_name: itemListName || null,
      item_list_id: listId?.toLowerCase() || null,
      item_name: product.name ? product.name.toLowerCase() : '',
      item_size: product.size || '',
      index: indexForElement,
      price: product.prices?.current ? product.prices.current : 0,
      price_eur: product.prices?.currentEur ? product.prices.currentEur : 0,
      price_net: product?.prices?.netPrice ?? 0,
      discount: getAppliedDiscount(product?.prices),
      discount_eur: product.prices?.discountEur ? product.prices.discountEur : 0,
      currency: product.prices?.currencySap || product.prices?.currency || '',
    };

    if (addToCartDataFromOrigin) {
      const { index, item_list_name, item_list_id } = addToCartDataFromOrigin;

      newProductsEcommerce[i] = {
        ...newProductsEcommerce[i],
        index: indexForElement,
        item_list_id: newProductsEcommerce?.[i]?.item_list_id || item_list_id || null,
        item_list_name: newProductsEcommerce?.[i]?.item_list_name || item_list_name || null,
      };
    }

    handleStorage(
      'setItem',
      'gtmItems',
      JSON.stringify({
        ...itemsDataParsed,
        [product.id]: newProductsEcommerce[i],
      }),
    );
  });

  return newProductsEcommerce;
};

export const parseDetailEcommerce = (product, gtmPropsKeys, size) => {
  const currentPrice = product.prices === null ? 0 : product.prices.current;
  const brand = size !== undefined ? size : '';
  const detail = {
    products: [
      {
        id: product.code,
        category: product.category,
        price: currentPrice,
        name: product.brand,
        brand,
        quantity: '1',
        coupon: '',
      },
    ],
    actionField: { list: `${gtmPropsKeys.gender}-familia ${gtmPropsKeys.family_id}-${product.concept}` },
  };

  return detail;
};

export const parseProductClickEcommerce = (product, ref) => {
  const currentPrice = product.prices === null ? 0 : product.prices.current;
  const detail = {
    products: [
      {
        id: product.id,
        category: product.category,
        price: currentPrice,
        name: product.name,
        brand: '',
        quantity: '1',
        coupon: '',
      },
    ],
    actionField: { list: ref },
  };

  return detail;
};

export const parseProductClickEcommerceGA4 = (product, index) => {
  const currentPrice = product.prices === null ? 0 : product.prices.current;
  const currentPriceEur = product.prices === null ? 0 : product.prices.currentEur;
  const productCategory = product.category?.split('/') || '';

  const itemsData = handleStorage('getItem', 'gtmItems');
  const itemsDataParsed = itemsData === undefined ? {} : JSON.parse(itemsData);
  const selectData = itemsDataParsed[product.id];

  // cualquier producto que pase por aquí tiene que haber pasado
  // por un view_item_list sí o sí así que selectData siempre
  // tiene que tener datos

  const items = [
    {
      item_name: product.name ? product.name.toLowerCase() : '',
      item_id: product.id,
      item_brand: IS_CAMPER ? 'camper' : 'nnormal',
      item_category: productCategory?.length && productCategory[0] in categories ? categories[productCategory[0]] : '', // 'women
      item_category2: productCategory?.length >= 2 ? productCategory[1].toLowerCase() : '', // shoes
      item_category3: productCategory?.length >= 2 ? productCategory[2].toLowerCase() : '', // sandals
      item_category4: product.nameBase?.toLowerCase() || '', // <linea>
      item_category5: product.size || '', // DISCOUNT-....
      item_variant: product.color?.toLowerCase() || '',
      item_list_name: selectData?.item_list_name || null,
      item_list_id: selectData?.item_list_id || null,
      index: index !== undefined ? Number(index) : '',
      currency: product.prices?.currencySap || product?.prices?.currency || '',
      price: currentPrice,
      price_eur: currentPriceEur,
      price_net: product?.prices?.netPrice ?? 0,
      discount: getAppliedDiscount(product?.prices),
      discount_eur: product.prices?.discountEur || 0,
    },
  ];

  return items;
};

export const searchProductsGTM = (products) => {
  const items = [];

  if (products) {
    products?.forEach((product, i) => {
      const position = i + 1;
      items[i] = {
        id: product.id,
        brand: '',
        coupon: '',
        name: product.name,
        position,
        category: product.category || '',
        price: product.prices?.current,
      };
    });
  }

  return items;
};

export const parseImpressionsProductsEcommerce = (recents, suggestions, code) => {
  const newImpressionsEcommerce = [];
  let cont = 0;

  if (recents) {
    const items = recents.filter((item) => item.id !== code);
    items?.forEach((recent, i) => {
      cont++;
      const position = i + 1;
      newImpressionsEcommerce[i] = {
        id: recent.id,
        brand: '',
        coupon: '',
        category: recent.category,
        list: 'Recently_viewed',
        name: recent.name,
        position,
        price: recent.prices?.current,
      };
    });
  }

  if (suggestions) {
    suggestions?.forEach((suggestion, i) => {
      i = i + cont;
      const position = i + 1;
      newImpressionsEcommerce[i] = {
        id: suggestion.id,
        brand: '',
        coupon: '',
        category: suggestion.category,
        list: 'You_may_also_be_interested',
        name: suggestion.name,
        position,
        price: suggestion.prices?.current,
      };
    });
  }

  return newImpressionsEcommerce;
};

export const checkFilters = (filters) => {
  const key = [];
  const value = [];
  if (filters) {
    const auxFilter = Object.keys(filters);

    auxFilter?.forEach((element) => {
      if (element !== 'first') getfilter(filters[element], element, key, value);
    });
  }

  return { key, value };
};

export const parseBagItemsEcommerceGA4 = (bag, itemIndex) => {
  const items = [];
  const gtmExtra = getGTMStorage();

  const { itemListName, itemListId } = gtmExtra;

  const itemsData = handleStorage('getItem', 'gtmItems');
  const itemsDataParsed = itemsData === undefined ? {} : JSON.parse(itemsData);

  bag?.forEach((item, i) => {
    const sku = item.productId || item.id;
    const addToCartDataFromOrigin = itemsDataParsed[sku];
    const currentPrice = item.price?.current || 0;
    const currentPriceEur = item.price?.currentEur || 0;
    const discountPriceEur = item.price?.discountEur || 0;
    const productCategory = item.category?.split('/');
    let coupon = productCategory && Array.isArray(productCategory) ? productCategory.pop() : '';

    if (coupon === 'DISCOUNT-0.0') {
      coupon = '';
    }

    items[i] = {
      item_name: item.name?.toLowerCase() || '',
      item_id: sku,
      price: currentPrice,
      price_eur: currentPriceEur,
      price_net: item?.price?.netPrice ?? 0,
      coupon,
      discount: getAppliedDiscount(item?.price),
      discount_eur: discountPriceEur,
      currency: item.price?.currencySap || item.price.currency || '',
      item_size: item.size || '',
      item_brand: IS_CAMPER ? 'camper' : 'nnormal',
      item_category: item.target in categories ? categories[item.target] : '', // 'women
      item_category2: productCategory?.length >= 2 ? productCategory[1].toLowerCase() : '', // shoes
      item_category3: productCategory?.length >= 2 ? productCategory[2].toLowerCase() : '', // sandals
      item_category4: item.name?.toLowerCase() || '', // <linea>
      item_category5: item.size || '',
      item_variant: item.color?.toLowerCase() || '',
      item_list_name: itemListName || null,
      item_list_id: itemListId || null,
      quantity: 1,
      index: itemIndex || i + 1,
    };

    if (addToCartDataFromOrigin) {
      const { index, item_list_name, item_list_id } = addToCartDataFromOrigin;

      items[i] = {
        ...items[i],
        index,
        item_list_id: item_list_id?.toLowerCase() || null,
        item_list_name: item_list_name || null,
      };
    }
  });
  return items;
};
