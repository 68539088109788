import { useEffect, useRef } from 'react';
import dynamic from 'next/dynamic';

import { useI18n } from 'context/i18n';

import { getDomainValue } from 'utils/domain';

import styles from './style.module.css';

const ProductsRows = dynamic(() => import('components/ProductsRow'));

export default function RecommendedBag() {
  const { t, locale, recommendedProductsForBag } = useI18n();
  const isMobile = useRef(false);

  useEffect(() => {
    isMobile.current = window.innerWidth <= 500;
  }, []);

  if (!Array.isArray(recommendedProductsForBag) || recommendedProductsForBag.length === 0) {
    return null;
  }

  const currentList = 'Add_to_bag_may_also_be_interested';

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.25,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    easing: true,
    draggable: true,
    arrows: false,
    swipe: true,
    useTransform: false,
    touchThreshold: 50,
    responsive: [
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2.25,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 3.25,
        },
      },
      {
        breakpoint: 668,
        settings: {
          slidesToShow: 4.25,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4.75,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.25,
        },
      },
    ],
  };

  // quitamos los duplicados y ordenamos por la propiedad order
  const recommendedForDisplay = recommendedProductsForBag
    .reduce((acc, current) => {
      if (!acc.some((item) => item.id === current.id)) {
        acc.push({
          ...current,
          images: {
            full: { main: current.imageBag },
            lg: { main: current.imageBag },
            md: { main: current.imageBag },
            xs: { main: current.imageBag },
          },
        });
      }
      return acc;
    }, [])
    .sort((a, b) => a.order - b.order);

  const [firstProduct] = recommendedForDisplay;
  const recommendedTitle = firstProduct?.sunnei ? t('ficha.producto', 'completa.sunnei') : t('ficha.producto', 'tambien.puede.interesarte');

  return (
    <div className={styles.recommendedBagWrapper}>
      <span className={styles.title} data-trackingga4={`${currentList?.toLowerCase()}|slider`}>
        {recommendedTitle}
      </span>
      <ProductsRows
        title=""
        locale={locale}
        source={recommendedForDisplay}
        currentList={currentList}
        slider={true}
        camperlab={getDomainValue({
          camper: false,
          nnormal: false,
          camperlab: true,
        })}
        withHover={getDomainValue({
          camper: !isMobile.current,
          nnormal: !isMobile.current,
          camperlab: false,
        })}
        fixedGrid={false}
        forcedSettings={sliderSettings}
        withPlaceholder={true}
        loading={null}
        addToBagButton={getDomainValue({
          camper: true,
          nnormal: true,
          camperlab: false,
        })}
        goToStartOnChange
        squareImg={getDomainValue({
          camper: true,
          nnormal: true,
          camperlab: false,
        })}
      />
    </div>
  );
}
