import { useRef, useState, useEffect } from 'react';
import { any, func } from 'prop-types';

import { Carousel } from 'antd';

import { ChevronSVG } from 'components/iconsSVG/chevron';

import { IS_CAMPER } from 'utils/constants/system';
import styles from './style.module.css';

export default function Ribbon({ items, onClickNewsletter, show }) {
  const carouselRef = useRef();
  const componentRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const [expandedContent, setExpandedContent] = useState(null);
  const expandedContentId = 'expanded-content';
  useEffect(() => {
    const handleClickOutside = (event) => {
      const eventId = event?.target?.id;
      if (componentRef.current && !componentRef.current.contains(event.target) && eventId !== expandedContentId) {
        setExpanded(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const getAnchorId = (content) => {
    const { anchorId } = content;

    if (anchorId) {
      const [, , id] = anchorId.split('.');

      if (id) {
        return id;
      }
    }

    return false;
  };

  const handleDataLayer = (content) => {
    if (window && window?.dataLayer) {
      const id = getAnchorId(content);
      if (id) {
        window.dataLayer.push({
          event: 'eventoEC',
          eventCat: 'ecommerce',
          eventAct: 'headerRibbonClick',
          eventLbl: id,
        });
      }
    }
  };

  const handleOnClickRibbon = (content) => {
    handleDataLayer(content);
    if (content.type === 'NEWSLETTER') {
      onClickNewsletter();
    }
  };

  const handleOnClickExpand = (content) => {
    handleDataLayer(content);
    setExpanded(!expanded);
    setExpandedContent(content.detail);
  };

  const handleOnClickChevron = (next = true) => {
    setExpanded(false);
    if (next) {
      carouselRef.current.next();
    } else {
      carouselRef.current.prev();
    }
  };

  return (
    <>
      {items && items.length > 0 && (
        <div ref={componentRef} className={!show ? styles.ribbonDiv : `${styles.ribbonDiv} ${styles.ribbonDivShow}`} id="header-ribbon">
          <Carousel effect="fade" dots={false} ref={carouselRef} autoplay={!expanded}>
            {items.map((content, index) => (
              <div key={index}>
                <div className={styles.ribbonContent}>
                  <div>
                    {items.length > 1 || IS_CAMPER ?
                      <div className={styles.chevron} onClick={() => handleOnClickChevron(false)}>
                        <ChevronSVG />
                      </div>
                    : null}
                    {content && content.type && content.type === 'DETAIL' && (
                      <div onClick={() => handleOnClickExpand(content)} className={styles.ribbonItemLink} dangerouslySetInnerHTML={{ __html: content.title }} />
                    )}
                    {content && content.type && content.type !== 'DETAIL' && (
                      <div
                        onClick={() => handleOnClickRibbon(content)}
                        className={content.type === 'NEWSLETTER' ? styles.ribbonItemLink : styles.ribbonItem}
                        dangerouslySetInnerHTML={{ __html: content.title }}
                      />
                    )}
                    {items.length > 1 || IS_CAMPER ?
                      <div className={styles.chevron} onClick={handleOnClickChevron}>
                        <ChevronSVG />
                      </div>
                    : null}
                  </div>
                  {content.detail !== '' && content.type !== 'NEWSLETTER' && (
                    <>
                      <div
                        className={!expanded ? styles.chevronExpand : `${styles.chevronExpand} ${styles.chevronExpanded}`}
                        onClick={() => handleOnClickExpand(content)}
                      >
                        <ChevronSVG />
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      )}
      {expanded && expandedContent && <div id={expandedContentId} className={styles.expandedContent} dangerouslySetInnerHTML={{ __html: expandedContent }} />}
    </>
  );
}

Ribbon.propTypes = {
  items: any,
  onClickNewsletter: func,
  show: any,
};
