import { useI18n } from 'context/i18n';
import { useLocale } from 'hooks';
import { FAMILIES, IS_CAMPER, IS_NNORMAL, TARGET_SIZE_LABEL_MARKETS } from 'utils/constants';
import { getCountryFromMarket } from 'utils/translations';

import styles from './style.module.css';
import { formatCurrency, getFixedPrice, nnormalSizesMap, obtainVoucherPrices } from 'utils/helpers';
import { ProgressiveImg } from 'components/base';
import { CloseSVG } from 'components/iconsSVG/close';

export function MiniCartItem({ product, onDelete }) {
  const { profileData, t, contextFilters, activatedPrevouchers } = useI18n();
  const { locale, country: localeCountry } = useLocale();

  const country = getCountryFromMarket(localeCountry);

  const showPrice = product.parentId !== undefined ? false : true;
  let parsedTarget = 'M';
  if (country === 'US') {
    parsedTarget = 'US';
  } else if (['M', 'W'].includes(product.target)) {
    parsedTarget = product.target;
  }
  let sizeLabel = null;
  if (IS_CAMPER) {
    const useSizeEquivalence =
      product.size && product.sizeLabel && product.size !== product.sizeLabel && [FAMILIES.SHOES, FAMILIES.ACCESSORIES].includes(Number(product.family));
    sizeLabel =
      useSizeEquivalence && !TARGET_SIZE_LABEL_MARKETS.includes(localeCountry) ?
        <span className={styles.size}>{`${product.sizeLabel} US / ${product.size} EU`}</span>
      : <span className={styles.size}>
          {product.camperLabPan === true ?
            'US'
          : product.sizeLabel || product.size ?
            country
          : ''}{' '}
          {TARGET_SIZE_LABEL_MARKETS.includes(localeCountry) && product.family && Number(product.family) === FAMILIES.SHOES ?
            `${product.gender ?? product.target} ${product.sizeLabel || product.size}`
          : product.sizeLabel || product.size}
        </span>;
  }

  const fixedPrice = getFixedPrice(localeCountry, profileData, product?.price?.current);
  const voucherPriceInfo = obtainVoucherPrices({ ...product, prices: product.price }, t, locale, contextFilters, activatedPrevouchers, false, true);
  let info: Record<string, any> = {};
  const oldDiscount = product.price.discount;

  if (voucherPriceInfo) {
    info = voucherPriceInfo.info || {};

    if (!voucherPriceInfo?.message) {
      product.price.voucherDiscount = info.discount;

      const infoOverride = { ...info };

      delete infoOverride.discount;
      product.price = { ...product.price, ...infoOverride };
    }
  }
  const { price } = product;
  const { voucherType } = price;
  let { voucherDiscount } = price;

  if (voucherDiscount !== undefined && voucherDiscount < 0) {
    voucherDiscount *= -1;
  }

  const discountToDisplay =
    oldDiscount ?
      voucherType === 'original.price' ?
        voucherDiscount || oldDiscount
      : oldDiscount
    : voucherDiscount || oldDiscount;

  const voucherTypeToUseInLabel = oldDiscount !== undefined ? voucherType : 'original.price';
  let appliedLabel = t('listado.productos', `voucher.price.message.${voucherTypeToUseInLabel}.applied`);

  if (voucherType === 'final.price' && oldDiscount !== undefined) {
    appliedLabel = appliedLabel.replace('<TAG discount>', voucherDiscount);
  }

  const appliedLabelToDisplay = info?.appliedLabel || voucherType ? appliedLabel : null;
  return (
    <div className={styles.menuItem}>
      <a href={product.url}>
        <ProgressiveImg
          src={product.thumbnail}
          alt={`bag image for ${product.productId}`}
          className={styles.productImg}
          width={90}
          height={90}
          iscamperone={product.camperoneOrderId !== null && product.camperoneOrderId !== undefined}
        />
      </a>
      <div className={styles.productInfo}>
        <p className={styles.productInfoName}>{product.name}</p>
        <p className={IS_NNORMAL ? styles.productDescription : undefined}>{IS_NNORMAL ? product.subtitleH1 : product.productLabel || product.productId}</p>
        <p>
          {IS_NNORMAL ?
            <span className={styles.productDescription}>{nnormalSizesMap?.[parsedTarget]?.[product.size] ?? product.size}</span>
          : sizeLabel}
        </p>
        {showPrice ?
          <p className={styles.productInfoPrice}>
            {product.price.previous && product.price.previous > product.price.current && (
              <s data-ge-basket-productlistprice="data-ge-basket-productlistprice" className={styles.itemDiscount}>
                {product.price.previousFormated || `${product.price.previous} ${formatCurrency(product.price.currency)}`}
              </s>
            )}
            {discountToDisplay && discountToDisplay > 0 && (
              <span
                className={`${product.price.discountFromVoucher ? `${styles.itemDiscount} ${styles.discountByVoucher}` : styles.itemDiscount} green`}
              >{`-${discountToDisplay}%`}</span>
            )}
            <span data-fp={fixedPrice} data-ge-price="data-ge-price" data-ge-basket-productsaleprice="data-ge-basket-productsaleprice">
              {product.price.currentFormated || `${product.price.current} ${formatCurrency(product.price.currency)}`}
            </span>
          </p>
        : null}
        {showPrice && appliedLabelToDisplay ?
          <span className={`${styles.productInfoPrice} ${styles.discountByVoucher}`}>{appliedLabelToDisplay}</span>
        : null}
      </div>
      {onDelete ?
        <button className={styles.removeProduct} onClick={() => onDelete(product.id)}>
          <CloseSVG />
        </button>
      : null}
    </div>
  );
}
