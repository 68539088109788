import { useState, useCallback, useEffect } from 'react';
import useEmblaCarousel from 'embla-carousel-react';

import { ChevronLeftSVG } from 'components/iconsSVG/chevronLeft';
import { ProgressiveImg } from 'components/base';
import { useI18n } from 'context/i18n';
import { dataLayerHandleEvent } from 'utils/dataLayers';

import styles from './style.module.css';
import { getDomainValue } from 'utils/domain';

export function ProductBrands({ id, products, className = '', placement = null, camperlab = false }) {
  const { t } = useI18n();
  const [emblaRef, emblaAPI] = useEmblaCarousel({
    skipSnaps: true,
    containScroll: 'trimSnaps',
  });
  const [previousButtonEnabled, setPreviousButtonEnabled] = useState(false);
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);

  const scrollPrevious = useCallback(() => emblaAPI && emblaAPI.scrollPrev(), [emblaAPI]);
  const scrollNext = useCallback(() => emblaAPI && emblaAPI.scrollNext(), [emblaAPI]);

  let filteredBrandProducts = [];

  if (products.length > 0) {
    filteredBrandProducts = products?.filter((item) => item.url);
  }

  const hasItems = filteredBrandProducts.length > 0;

  const handleClickBrandProduct = (productBrand) => {
    dataLayerHandleEvent({
      event: 'eventGA',
      eventCat: 'productDetail',
      eventAct: 'selectColour',
      eventLbl: productBrand.id,
    });

    dataLayerHandleEvent({
      event: 'item_photos',
      event_category: 'product detail',
      event_detail_1: 'open',
      event_detail_2: productBrand.image,
    });
  };

  const handleClickButton = (event, buttonType) => {
    event.preventDefault();
    event.stopPropagation();

    const scrollFunction = buttonType === 'next' ? scrollNext : scrollPrevious;

    scrollFunction();
  };

  const onSelect = useCallback(() => {
    if (!emblaAPI) return;
    setPreviousButtonEnabled(emblaAPI.canScrollPrev());
    setNextButtonEnabled(emblaAPI.canScrollNext());
  }, [emblaAPI]);

  useEffect(() => {
    if (!emblaAPI) return;
    onSelect();
    emblaAPI.on('select', onSelect);
  }, [emblaAPI, onSelect]);

  if (!hasItems) return null;

  const wrapperClassName = placement === null ? '-mx-8 overflow-hidden md:hidden' : 'overflow-hidden relative';
  const sliderClassName = placement === null ? 'mx-8' : '';
  const emblaStyle =
    placement === null ?
      {
        '--slide-height': '4.5rem',
        '--slide-gap': '0.625rem',
      }
    : {
        '--slide-height': '3rem',
        '--slide-gap': '0.2rem',
      };

  // el primero es si es giftCard el segundo si no es giftCard
  return (
    <div className={className}>
      <p className="mb-2 text-base camper:hidden lab:mb-0 lab:text-sm lab:uppercase">{t('ficha.producto', 'colores', 'Colores')}:</p>
      {(
        getDomainValue({
          camper: true,
          camperlab: true,
          nnormal: false,
        })
      ) ?
        <div className={wrapperClassName}>
          {placement === 'grid' && nextButtonEnabled && <div className={`${styles.gradient} ${camperlab ? styles.gradientLab : styles.gradientWhite}`} />}
          <div style={emblaStyle} ref={emblaRef} className={sliderClassName}>
            <div className="-ml-[var(--slide-gap)] flex touch-pan-y touch-pinch-zoom">
              {filteredBrandProducts.map((productBrand, index) => (
                <div
                  key={`${productBrand.id}-${index}`}
                  className={`relative ml-[var(--slide-gap)] min-w-0 shrink-0 grow-0 basis-auto lab:h-15 lab:w-10 ${productBrand.id === id ? 'after:absolute after:bottom-0 after:block after:h-0.5 after:w-full after:border-b-2 after:border-[var(--color-black)] lab:after:h-full lab:after:border-2' : ''}`}
                >
                  <a href={productBrand.url} onClick={(e) => handleClickBrandProduct(productBrand)}>
                    <span className="sr-only">
                      {productBrand.name} - {productBrand.id}
                    </span>
                    <ProgressiveImg
                      src={productBrand.image}
                      alt={`thumbnail image ${index} of ${productBrand.id}`}
                      height={48}
                      width={48}
                      className={styles.brandThumbnail}
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
          {placement === 'grid' && (
            <>
              {previousButtonEnabled && (
                <ChevronLeftSVG
                  onClick={(e) => handleClickButton(e, 'previous')}
                  className={`${styles.emblaButton} absolute left-[0.1rem] hidden bg-[#fff] md:flex`}
                />
              )}
              {nextButtonEnabled && (
                <ChevronLeftSVG
                  onClick={(e) => handleClickButton(e, 'next')}
                  className={`${styles.emblaButton} absolute right-[0.75rem] hidden rotate-180 bg-[#fff] md:flex`}
                />
              )}
            </>
          )}
        </div>
      : null}
      {placement !== 'grid' ?
        <div className="flex flex-row flex-wrap gap-2.5 camper:max-md:hidden lab:max-md:hidden md:gap-1 md:lab:gap-2.5">
          {filteredBrandProducts.map((productBrand, index) => (
            <div
              key={`${productBrand.id}-${index}`}
              className={`relative size-18 lab:h-15 lab:w-10 md:size-15 ${productBrand.id === id ? 'after:absolute after:bottom-0 after:block after:h-0.5 after:w-full after:border-b-2 after:border-[var(--color-black)] lab:after:h-full lab:after:border-2' : ''}`}
            >
              <a
                href={productBrand.url}
                className="h-full w-full [&_img]:h-full [&_img]:w-full [&_picture]:flex [&_picture]:h-full [&_picture]:w-full"
                onClick={(e) => handleClickBrandProduct(productBrand)}
              >
                <span className="sr-only">
                  {productBrand.name} - {productBrand.id}
                </span>
                <ProgressiveImg
                  src={productBrand.image}
                  alt={`thumbnail image ${index} of ${productBrand.id}`}
                  className={`${styles.brandThumbnail} size-18 md:size-15`}
                />
              </a>
            </div>
          ))}
        </div>
      : null}
    </div>
  );
}
