const currentDomain = process.env.NEXT_PUBLIC_DOMAIN;

function getDomainOld() {
  if (['camper', 'nnormal', 'camperlab'].includes(currentDomain)) {
    return currentDomain;
  }
  return 'camper';
}

/**
 * temporal hasta que podamos pasar helpers.js a TS
 */
function getDomainValueOld(args) {
  const domain = getDomainOld();

  return args[domain] ?? null;
}

module.exports = { getDomainValueOld };
